import { Box, Center, Link, Text } from '@chakra-ui/react';
import NextImage from 'next/image';
import { useEffect, useState } from 'react';
import { Autoplay, Keyboard, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

type CarouselElement = {
  trackingId: string;
  imgSrc: string;
  linkUrl: string;
  description: string;
};

const defaultSwiperWidth = 'calc(100vw - 380px)';
const defaultSwiperMarginLeft = 'calc(50% - 50vw + 190px)';
const swiperWidthForSP = '100vw';
const swiperMarginLeftForSP = 'calc(50% - 50vw)';

export default function Carousel() {
  const carouselElements: CarouselElement[] = [
    {
      trackingId: '141',
      imgSrc: '/images/carousel_img_20240624_hyperuricemia.png',
      linkUrl: '/special/hyperuricemia',
      description: '高尿酸血症特集',
    },
    {
      trackingId: '139',
      imgSrc: '/images/carousel_img_20240625_residence-new-apartment.png',
      linkUrl: '/special/residence-new-apartment',
      description: '【医師のライフスタイル】居住用新築マンション特集',
    },
    {
      trackingId: '143',
      imgSrc: '/images/carousel_img_20240905_dynamics2024.png',
      linkUrl: '/special/dynamics2024',
      description: 'ダイナミクス全国大会2024特集',
    },
    {
      trackingId: '142',
      imgSrc: '/images/career_20240628.png',
      linkUrl: 'https://career.antaa.jp/',
      description: '【キャリアWEBメディア】医師の「はたらく」を一緒に考える',
    },
    {
      trackingId: '76',
      imgSrc: '/images/carousel_img_20230124_antaa_slide_academy_lp.png',
      linkUrl: '/docs/academy',
      description: '【Antaa Slide Academy】スライド投稿のノウハウを無料で学ぶ',
    },
    {
      trackingId: '135',
      imgSrc: '/images/carousel_img_20240425_hyperkalemia_az.png',
      linkUrl: '/special/fec15a463248d6a9',
      description: '【Slide募集中】高カリウム血症',
    },
    {
      trackingId: '144',
      imgSrc: '/images/carousel_img_20240926_private_healthcare.png',
      linkUrl: '/special/private-healthcare',
      description: '【新たな選択肢】保険外診療特集',
    },
  ];

  // SPサイズの場合のみautoplayを有効化
  const [autoplayEnable, setAutoplayEnable] = useState(false);
  const [swiperWidth, setSwiperWidth] = useState(defaultSwiperWidth);
  const [swiperMarginLeft, setSwiperMarginLeft] = useState(
    defaultSwiperMarginLeft,
  );
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 639) {
        setAutoplayEnable(true);
      } else {
        setAutoplayEnable(false);
      }

      if (window.innerWidth <= 992) {
        setSwiperWidth(swiperWidthForSP);
        setSwiperMarginLeft(swiperMarginLeftForSP);
      } else {
        setSwiperWidth(defaultSwiperWidth);
        setSwiperMarginLeft(defaultSwiperMarginLeft);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Swiper
      key={`${autoplayEnable}`}
      modules={[Navigation, Autoplay, Keyboard]}
      centeredSlides
      keyboard
      navigation
      lazy={false}
      style={{
        height: '260px',
        paddingTop: '4px',
        paddingBottom: '30px',
        width: swiperWidth,
        marginLeft: swiperMarginLeft,
      }}
      className="carousel-swiper"
      slidesPerView={1}
      spaceBetween={100}
      /* 2つ以上のリンクを指定した場合は下記プロパティを有効にする */
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 100,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 200,
        },
        1024: {
          slidesPerView: 2.2,
          spaceBetween: 100,
        },
        1066: {
          slidesPerView: 2.4,
          spaceBetween: 100,
        },
        1170: {
          slidesPerView: 2.7,
          spaceBetween: 100,
        },
        1400: {
          slidesPerView: 3.2,
          spaceBetween: 100,
        },
        1600: {
          slidesPerView: 3.8,
          spaceBetween: 20,
        },
        1900: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
      autoplay={
        {
          enabled: autoplayEnable, // `autoplay: false` が効かないため、enableプロパティを用いてautoplayの有効/無効を切り替える
          delay: 3000,
          disableOnInteraction: false,
        } as any // I/F上に無いenabledプロパティにアクセスする都合、anyを指定する
      }
      loop
      loopedSlides={3}
    >
      {carouselElements.map((elm) => (
        <SwiperSlide
          key={elm.trackingId}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Link
            href={elm.linkUrl}
            isExternal={elm.linkUrl.startsWith('http')}
            color="linkText"
            onClick={() =>
              sendAnalyticsEvent('special', 'click', elm.trackingId)
            }
            style={{
              width: '320px',
            }}
          >
            <Center>
              <Box
                borderRadius="10px"
                overflow="hidden"
                w="320px"
                h="168px"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NextImage
                  src={elm.imgSrc}
                  width={320}
                  height={168}
                  layout="fixed"
                  alt={elm.description}
                  priority
                />
              </Box>
            </Center>
            <Text fontSize={'sm'} m={2}>
              {elm.description}
            </Text>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
